.site-header-inner {
    justify-content: start;

    strong {
        float: left;
        font-weight: 500;
        font-size: 0.8rem;
        margin-left: 30px;
        
        a {
            color: #9da0b5;
        }
    }
}

.shadow-container {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .shadow {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    
    p {
        color: #000;
        width: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: rgba(255, 255, 255, .8);
        z-index: 99999;
        border-radius: .5rem;
        padding: .6rem 1rem;
        font-size: .8rem;
    
        &::after {
            content: ' ';
            display: inline-block;
            width: .5rem;
            height: .5rem;
            border-style: solid;
            border-color: transparent transparent rgba(255, 255, 255, .8);
            border-width: .4rem;
            position: absolute;
            top: -16px;
            right: 1rem;
        }
    }
}

.qrcode {
    text-align: center;
    font-size: 0.8rem;
    padding: 10px 20px;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9999;
    display: none;
    
    i {
        display: inline-block;
        background-size: contain;
        background-image: url("../images/qrcode.png");
        width: 100px;
        height: 100px;
    }

    p {
        margin: 0;
    }
}

.footer-copyright {
    flex-direction: column;
}

.footer-links {
    span {
        margin-left: 6px;
    }
}

@media (min-width: 641px) {
    .qrcode {
        display: block;
    }
}